import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '../view/pk/PkIndexView'
import NotFound from '../view/error/NotFound'
import RankListIndexView from '../view/ranklist/RankListIndexView'
import RecordIndexView from '../view/record/RecordIndexView'
import RecordContentView from '../view/record/RecordContentView'
import UserBotIndexView from '../view/user/bots/UserBotIndexView'
import UserAccountLoginView from '../view/user/account/UserAccountLoginView'
import UserAccountRegisterView from '../view/user/account/UserAccountRegisterView'
import store from '../store/index'

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/pk",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/pk",
    component: PkIndexView,
    name: "pk_index",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record",
    component: RecordIndexView,
    name: "record_index",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/record/:recordId",
    component: RecordContentView,
    name: "record_content",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/ranklist",
    component: RankListIndexView,
    name: "ranklist_index",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/bot",
    component: UserBotIndexView,
    name: "user_bot_index",
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login",
    component: UserAccountLoginView,
    name: "user_account_login",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/user/account/register",
    component: UserAccountRegisterView,
    name: "user_account_register",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/404",
    component: NotFound,
    name: "404",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.meta.requestAuth && !store.state.user.is_login) {
    next({name: "user_account_login"})
  } else {
    next()
  }
})

export default router
