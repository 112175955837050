<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" style="width: 100%">
                    </div>
                </div>
            </div>
            <div class="col-9">
                <div class="card" style="margin-top: 20px">
                    <div class="card-header">
                        <span style="font-size: 130%">我的bot</span>
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add_bot_btn">
                            + 创建bot
                        </button>

                        <!-- Modal -->
                        <div class="modal fade" id="add_bot_btn" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5">创建bot</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div class="mb-3">
                                        <label for="add_bot_title" class="form-label">名称</label>
                                        <input v-model="botadd.title" type="text" class="form-control" id="add_bot_title" placeholder="请输入名称">
                                    </div>
                                    <div class="mb-3">
                                        <label for="add_bot_description" class="form-label">简介</label>
                                        <textarea v-model="botadd.description" class="form-control" id="add_bot_description" rows="3" placeholder="请输入介绍"></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="add_bot_code" class="form-label">代码</label>
                                        <VAceEditor
                                            v-model:value="botadd.content"
                                            @init="editorInit"
                                            lang="c_cpp"
                                            theme="textmate"
                                            style="height: 300px" />
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div class="error_message" style="color: red">
                                        {{botadd.error_message}}
                                    </div>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                    <button type="button" class="btn btn-primary" @click="add_bot">创建</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>创建时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot in bots" :key="bot.id">
                                    <td>{{bot.title}}</td>
                                    <td>{{bot.createTime}}</td>
                                    <td>
                                        <button type="button" class="btn btn-info"  data-bs-toggle="modal" :data-bs-target="'#update_bot_modal_' + bot.id" style="margin-right: 10px">修改</button>
                                        <button type="button" @click="remove_bot(bot)" class="btn btn-danger">删除</button>

                                        <div class="modal fade" :id="'update_bot_modal_' + bot.id" tabindex="-1">
                                            <div class="modal-dialog modal-xl">
                                                <div class="modal-content">
                                                <div class="modal-header">
                                                    <h1 class="modal-title fs-5">修改bot</h1>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="mb-3">
                                                        <label for="add_bot_title" class="form-label">名称</label>
                                                        <input v-model="bot.title" type="text" class="form-control" id="add_bot_title" placeholder="请输入名称">
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="add_bot_description" class="form-label">简介</label>
                                                        <textarea v-model="bot.description" class="form-control" id="add_bot_description" rows="3" placeholder="请输入介绍"></textarea>
                                                    </div>
                                                    <div class="mb-3">
                                                        <label for="add_bot_code" class="form-label">代码</label>
                                                        <VAceEditor
                                                            v-model:value="bot.content"
                                                            @init="editorInit"
                                                            lang="c_cpp"
                                                            theme="textmate"
                                                            style="height: 300px" />
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <div class="error_message" style="color: red">
                                                        {{bot.error_message}}
                                                    </div>
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                                    <button type="button" class="btn btn-primary" @click="update_bot(bot)">修改</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, reactive} from 'vue'
import $ from 'jquery'
import {useStore} from 'vuex'
import {Modal} from 'bootstrap/dist/js/bootstrap'
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';

export default {
    components: {
      VAceEditor  
    },
    setup() {
        const store = useStore()
        ace.config.set(
            "basePath", 
            "https://cdn.jsdelivr.net/npm/ace-builds@" + 
            require('ace-builds').version + "/src-noconflict/")  //集成ace编辑器

        let bots = ref([])
        const botadd = reactive({
            title: "",
            description: "",
            content: "",
            error_message: ""
        })

        const refresh_bots = () => {
            $.ajax({
                url: "https://www.ljxspace.top/api/user/bot/getlist",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                success(resp) {
                    bots.value = resp
                }
            })
        }

        refresh_bots()

        const add_bot = () => {
            botadd.error_message = ""
            $.ajax({
                url: "https://www.ljxspace.top/api/user/bot/add",
                type: "post",
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                success(resp) {
                    if(resp.error_message === "success") {
                        botadd.title = ""
                        botadd.description = ""
                        botadd.content = ""
                        Modal.getInstance("#add_bot_btn").hide()
                        refresh_bots()
                    } else {
                        botadd.error_message = resp.error_message
                    }
                }
            })
        }

        const remove_bot = (bot) => {
            $.ajax({
                url: "https://www.ljxspace.top/api/user/bot/remove",
                type: "post",
                data: {
                    bot_id: bot.id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                success(resp) {
                    if(resp.error_message === "success") {
                        refresh_bots()
                    }
                }
            })
        }

        const update_bot = (bot) => {
            botadd.error_message = ""
            $.ajax({
                url: "https://www.ljxspace.top/api/user/bot/update",
                type: "post",
                data: {
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                success(resp) {
                    if(resp.error_message === "success") {
                        Modal.getInstance('#update_bot_modal_' + bot.id).hide()
                        refresh_bots()
                    } else {
                        bot.error_message = resp.error_message
                    }
                }
            })
        }

        return {
            bots,
            botadd,
            add_bot,
            remove_bot,
            update_bot
        }
    }
}

</script>


<style scoped>

</style>