const AC_GAME_OBJECTS = [];  //���е���Ϸ����

export class AcGameObject {
    constructor() {
        AC_GAME_OBJECTS.push(this)
        this.timedelta = 0  //��һ֡������һ֡��ʱ��
        this.has_called_start = false  //�Ƿ�ִ�й�start
    }

    start() {  //ִֻ��һ��

    }

    update() {  //ÿִ֡�У�����һ֡
        
    }

    on_destory() {  //ɾ��ǰִ��

    }

    destroy() {  
        this.on_destory()
        for(let i in AC_GAME_OBJECTS) {
            const obj = AC_GAME_OBJECTS[i]
            if(obj == this) {
                AC_GAME_OBJECTS.splice(i)
                break
            }
        }
    }
}

let last_timestamp
const step = timestamp => {
    for(let obj of AC_GAME_OBJECTS) {
        if(!obj.has_called_start) {
            obj.has_called_start = true
            obj.start()
        } else {
            obj.timedelta = timestamp - last_timestamp
            obj.update()
        }
    }
    
    last_timestamp = timestamp
    requestAnimationFrame(step)  //ÿ֡������
}

requestAnimationFrame(step)  //��һ֡����